import {Obtener,Save,Editar} from '../../../Generico/Conexion';
import { mainAxios } from '@/plugins/axios';
export class Rol
{
    rolId:number = 0;
    descripcion:string = "";
}

export async function ObtenerRol(sistemaId:number)
{
    return Obtener<Rol>(`rol/obtenerRol/${sistemaId}`,mainAxios);
}