



































import {Vue,Component,Prop} from 'vue-property-decorator'
import {Rol,ObtenerRol} from '@/entidades/Maestro/Sistema/Rol';
import Usuario from '@/entidades/Maestro/Usuario';
import {Action} from 'vuex-class';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
import { SistemaModal,ObtenerSistema } from '@/entidades/Sistema/SitemaModal';
@Component({
    name : "RolUsuarioComponente"
})
export default class RolUsuarioComponente extends Vue
{
    sistemaId:number =0;
    rolId:number =0;
    listadoRol:Array<Rol>=[];
    listadoSistema:Array<SistemaModal> =[];
    @Prop({type:Object,required:true}) usuario!:Usuario;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;

    async obtenerRol()
    {
        try
        {
            let rsp = await ObtenerRol(this.sistemaId);
            if(rsp.isSuccess == true)
            {
                this.listadoRol = rsp.isResult;
            }
        }
        catch(error)
        {
            console.log(error);
        }
    }

    async guardar()
    {
        try
        {
            await this.changeLoading(new Loading(true,"Registrando informacion"));
            let userRol = new Usuario();
            userRol.usuarioId = this.usuario.usuarioId;
            userRol.rolId = this.rolId;
            userRol.sistemaId = this.sistemaId;

            let rsp = await Usuario.GuardarRol(userRol);
            if(rsp.isSuccess == true)
            {
                this.changeAlerta(new Alerta(rsp.isMessage,false,"success",2000));
                this.$emit('limpiar');
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,false,"danger",2000));
               
            }
        }
        catch(error)
        {
            //@ts-ignore
            this.changeAlerta(new Alerta(error,false,"danger",2000));
        }
        finally
        {
            await this.changeLoading(new Loading(false,""));
        }
    }
    async mounted() 
    {
       // this.ObtenerRol();
       this.listadoSistema = (await ObtenerSistema()).isResult;
        this.rolId = this.usuario.rolId;
    }   
}
